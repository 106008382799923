:root {
    /*variaveis*/
    --bg-dark: #EF5A2F;
    --logo-height: 150px;
    --header-height: 70px;
    --aside-width: 300px;
    --menu-top-height: 70px;
    --footer-height: 30px;
    --shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.1), 0 2px 49px 0 rgba(0, 0, 0, 0.05);
}

* {
    box-sizing: border-box;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.homeApp {
    margin: 0px;
    display: grid;
    grid-template-columns: var(--aside-width) 1fr;
    grid-template-rows: var(--header-height) 1fr var(--footer-height);
    grid-template-areas: "menu header" "menu content" "menu footer";
    height: 100vh;
    background-color: #F5F5F5;
}

header.logo {
    grid-area: logo;
}

header.header {
    grid-area: header;
}

aside.menu-area {
    grid-area: menu;
}

main.content {
    grid-area: content;
}

footer.footer {
    grid-area: footer;
}

@media(max-width: 768px) {
    .HomeApp {
        grid-template-rows: var(--header-height) var(--menu-top-height) 1fr;
        grid-template-columns: var(--aside-width) 1fr;
        grid-template-areas: "menu menu" "content content" "content content";
    }
}