.devForm {
    overflow: auto;
    height: 25vh;
    align-items: flex-start;
    border-bottom: 15px;
}

#div-buttons {
    margin-bottom: 20px;
}

.dataForm {
    overflow: auto;
    align-items: flex-start;
}

.devForm button {
    color: #fff;
    font-size: 16px;
    background: rgb(15, 32, 108);
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 80%;
}

.vbCol {
    display: flex;
    overflow: auto;
    margin: 0px;
    padding: 0px;
}

.vbCol select {
    width: 50vw;
}

.impTtl {
    margin: 0px;
    padding: 0px;
}

.vbCol input {
    height: 46px;
    padding: 0 15px;
    border-radius: 2px;
    color: #777;
    display: flex;
    font-size: 20px;
    width: 100%;
    border: 1px solid #ddd;
}

.vbCol div {
    margin-left: 15px;
}

.vbCol h6 {
    margin: 5px;
}

.devForm input {
    height: 46px;
    padding: 0 10px;
    border-radius: 2px;
    color: #777;
    display: flex;
    font-size: 20px;
    width: 80%;
    border: 1px solid #ddd;
}

.devF .devForm p {
    color: #ff3333;
    margin-bottom: 15px;
    padding: 10px;
    width: 100%;
    text-align: center;
}

.table-container {
    border-collapse: collapse;
    /* Collapse borders */
    width: 100%;
    /* Full-width */
    border: 1px solid #ddd;
    /* Add a grey border */
    font-size: 16px;
    /* Increase font-size */
    overflow: auto;
}

.table-container input {
    overflow: hidden;
    width: 70px;
}

.table-container tbody {
    overflow: auto;
    height: 40vh;
    width: 100%;
}

.table-container button {
    color: #000;
    font-size: 16px;
    background: var(--bg-dark);
    height: 25px;
    border: 0;
    border-radius: 5px;
    width: auto;
    margin-left: 15px;
    margin-top: 0;
    align-items: center;
}

.table-container th,
.table-container td {
    text-align: left;
    /* Left-align text */
    padding: 12px 8px 12px 8px/* Add padding */
}

.table-container tr {
    /* Add a bottom border to all table rows */
    border-bottom: 1px solid #ddd;
}

.table-container thead {
    background-color: #F67F3B;
    width: auto;
}