.sendFileButtons {
    color: #fff;
    font-size: 16px;
    background: #339933;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 20vw;
    margin-left: 0px;
    margin-top: 0;
    align-items: center;
}

.sendFileInput {
    height: 36px;
    font-size: 18px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 5px;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
    width: 600px;
    margin: 10px;
}