header.header {
    background-color: #EF5A2F;
    padding: 0px 15px;
    overflow: hidden;
    white-space: nowrap;
    justify-content: center;
}

header.header h1 {
    font-size: 1.8em;
    color: rgb(15, 32, 108);
}

@media(max-width: 768px) {
    header.header {
        display: none;
    }
}