footer.footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 25px;
    background-color: #f5f5f5;
    box-shadow: var(--shadow);
    width: 50%;
}

@media(max-width: 768px) {
    footer.footer {
        display: none;
    }
}