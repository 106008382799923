#finaltable-div{
    height: 53vh;
    overflow: auto;
}

#submit-button{
    color: #fff;
    font-size: 16px;
    background: #339933;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 25%;
    margin-left: 25px;
    margin-top: 0;
    align-items: center;
    }
    
    #submit-button:hover{
        background: linear-gradient(135deg, #004400 0%, #33AA33 100%);
    }

#final-table {
    border-collapse: collapse; /* Collapse borders */
    width: 100%; /* Full-width */
    border: 1px solid #F67F3B; /* Add a grey border */
    font-size: 16px; /* Increase font-size */
  }
  #final-table th, #final-table td {
    text-align: left; /* Left-align text */
    padding: 12px; /* Add padding */
    
  }
  
  #final-table tr {
    /* Add a bottom border to all table rows */
    border-bottom: 1px solid #F67F3B;
    
  }
  
   #final-table tr:hover {
    /* Add a grey background color to the table header and on hover */
    background-color: #fff1f1;
    cursor: pointer;
  }

  #final-table th{
    background-color: #F67F3B;
  }

  