aside.logo {
    background-color: var(--bg-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}

aside.logo img {
    padding: 15px;
    height: var(--logo-height);
    width: auto;
}