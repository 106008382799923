.dataForm {
    height: 75vh;
}

.dataForm textarea {
    height: 15vh;
    margin-bottom: 15px;
    padding: 0 20px;
    border-radius: 2px;
    color: #333;
    display: flex;
    font-size: 15px;
    width: 90%;
    border: 1px solid #999;
}

.dataForm input {
    height: 46px;
    font-size: 18px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 5px;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
    width: 250px;
}

#div-buttons {
    display: inline-flex;
}

[type="date"] {
    background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat;
}

[type="date"]::-webkit-inner-spin-button {
    display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}

#div-buttons #voltar-button {
    color: #fff;
    font-size: 16px;
    background: #FF3333;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 20vw;
    margin-left: 0px;
    margin-top: 0;
    align-items: center;
}

#voltar-button:hover {
    background: linear-gradient(135deg, #FF0000 0%, #FF3333 100%);
}

#div-buttons #avanc-button {
    color: #fff;
    font-size: 16px;
    background: #339933;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 20vw;
    margin-left: 25px;
    margin-top: 0;
    align-items: center;
}

#avanc-button:hover {
    background: linear-gradient(135deg, #004400 0%, #33AA33 100%);
}

@media(max-width: 768px) {
    .dataForm textarea {
        height: 8vh;
        margin-bottom: 15px;
        padding: 0 20px;
        border-radius: 2px;
        color: #333;
        display: flex;
        font-size: 15px;
        width: 90%;
        border: 1px solid #999;
    }
    .dataForm input {
        height: 8vh;
        font-size: 18px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        background-color: #fff;
        padding: 3px 5px;
        box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
        width: 250px;
    }
    .dataForm h5 {
        font-size: 16px;
    }
}