main>div {
    background-color: #FFF;
    color: rgb(15, 32, 108);
    box-shadow: 0 px 0px 0px #000004;
}

@media(max-width: 768px) {
    .mainHeader {
        display: none;
    }
}