#voltar-button{

  color: #fff;
  font-size: 16px;
  background: #FF3333;
  height: 43px;
  border: 0;
  border-radius: 5px;
  width: 25%;
  margin-left: 0px;
  margin-top: 0;
  align-items: center;
  }
  
  #voltar-button:hover{
  
  background: linear-gradient(135deg, #FF0000 0%, #FF3333 100%);
  
  }
  
  #avanc-button{
    color: #fff;
    font-size: 16px;
    background: #339933;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 25%;
    margin-left: 25px;
    margin-top: 0;
    align-items: center;
    }
    
    #avanc-button:hover{
        background: linear-gradient(135deg, #004400 0%, #33AA33 100%);
    }