div.infos {
    height: 60vh;
    width: 100%;
    overflow: auto;
    margin-bottom: 15px;
    align-items: flex-start;
}

div.infos div {
    display: inline;
}

div.detalhes h5,
p {
    display: inline;
    margin-left: 0px;
}

div.infos hr {
    margin-top: 5px;
    margin-left: 0px;
    width: 60%;
}

div.detalhes hr {
    margin-bottom: 5px;
}

@media(max-width: 768px) {
    div.detalhes h2 {
        font-size: 18px;
    }
}