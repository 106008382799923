body,
html {
    height: 100%;
    width: 100%;
    background: #FF5100;
}

div.container {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100vh;
}

form.form {
    width: 400px;
    background: #EEE;
    padding: 20px;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    box-shadow: var(--shadow);
}

.form img {
    width: 250px;
    margin: 10px 0 40px;
    display: flex;
}

.form p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 2px solid #ff3333;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    text-align: center;
}

.form input {
    height: 46px;
    margin-bottom: 15px;
    padding: 0 20px;
    border-radius: 2px;
    color: #777;
    display: flex;
    font-size: 15px;
    width: 100%;
    border: 1px solid #ddd;
}

.form input ::placeholder {
    color: #999;
}

.form button {
    color: #fff;
    font-size: 16px;
    background: rgb(15, 32, 108);
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 100%;
}

button:hover {
    background: linear-gradient(135deg, rgb(15, 32, 108) 0%, rgb(25, 40, 180) 100%);
}

.form hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
}

.form a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
}