/*:root{

    
    --bg-dark : #1A2F3A;

    --logo-height: 100px;
    --header-height: 100px;
    --aside-width: 225px;
    --footer-height: 40px;
    --menu-top-height: 70px;

    --shadow:
        0 2px 23px 0 rgba(0, 0, 0, 0.1),
        0 2px 49px 0 rgba(0, 0, 0, 0.05);
}
*/

.HomeApp {
    margin: 0px;
    display: grid;
    grid-template-columns: var(--aside-width) 1fr;
    grid-template-rows: var(--header-height) 1fr var(--footer-height);
    grid-template-areas: "menu header" "menu content" "menu footer";
    height: 100vh;
    background-color: #FFE0D8;
}

aside.logo {
    grid-area: logo;
}

header.header {
    grid-area: header;
}

aside.menu-area {
    grid-area: menu;
}

main.content {
    grid-area: content;
}

footer.footer {
    grid-area: footer;
}