#ok-button {
    color: #fff;
    font-size: 16px;
    background: #339933;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 25%;
    margin-left: 25px;
    margin-top: 0;
    align-items: center;
}

#ok-button:hover {
    background: linear-gradient(135deg, #004400 0%, #33AA33 100%);
}

#msg-div {
    height: 55vh;
}

#resp-msg {
    font-size: 22px;
}