aside.menu-area {
    background-color: var(--bg-dark);
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.12), 2px 0 15px 0 rgba(0, 0, 0, 0.09);
}

.menu a {
    display: block;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: #FFF;
    padding: 15px;
}

.menu i {
    color: rgb(15, 32, 108);
}

.menu#navv a:hover {
    background: rgb(15, 32, 108);
    color: white;
    -o-transition: .6s;
    -ms-transition: .6s;
    -moz-transition: .6s;
    -webkit-transition: .6s;
    /* ...and now for the proper property */
    transition: .6s;
}

.menu#navv a:hover i {
    color: white
}

.menu hr {
    margin: 0px 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.55);
}

#logoNav {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
}

@media(max-width: 768px) {
    #logoNav {
        display: none;
    }
    .menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        justify-content: space-around;
    }
    .menu a {
        display: inline;
        padding: 7px;
        margin: 0px;
    }
}