#main-div {
    overflow: auto;
    height: 55vh;
}

#filtro {
    /*background-image: url('/css/searchicon.png');
    /* Add a search icon to input */
    background-position: 10px 12px;
    /* Position the search icon */
    background-repeat: no-repeat;
    /* Do not repeat the icon image */
    width: 100%;
    /* Full-width */
    font-size: 16px;
    /* Increase font-size */
    padding: 12px 20px 12px 40px;
    /* Add some padding */
    border: 1px solid #ddd;
    /* Add a grey border */
    margin-bottom: 12px;
    /* Add some space below the input */
}

#saldos-table {
    border-collapse: collapse;
    /* Collapse borders */
    width: 100%;
    /* Full-width */
    border: 1px solid #F67F3B;
    /* Add a grey border */
    font-size: 16px;
    /* Increase font-size */
}

#saldos-table th,
#saldos-table td {
    text-align: left;
    /* Left-align text */
    padding: 12px;
    /* Add padding */
}

#saldos-table tr {
    /* Add a bottom border to all table rows */
    border-bottom: 1px solid #F67F3B;
}

#saldos-table tr:hover {
    /* Add a grey background color to the table header and on hover */
    background-color: #fff1f1;
    cursor: pointer;
}

#saldos-table th {
    background-color: #F67F3B;
}

#saldos-table .vermelho {
    color: #ff0f0f;
}

#saldos-table .verde {
    color: #0fcf0f;
}