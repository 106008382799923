form.cadastro {
    overflow: auto;
    height: 75vh;
    align-items: flex-start;
}

.cadastro h6 {
    font-size: 16;
    font-weight: bold;
    color: rgb(15, 32, 108);
    text-decoration: none;
}

.cadastro p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 2px solid #ff3333;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    text-align: center;
}

.cadastro input {
    height: 46px;
    margin-bottom: 15px;
    padding: 0 20px;
    border-radius: 2px;
    color: #777;
    display: flex;
    font-size: 20px;
    width: 80%;
    border: 1px solid #ddd;
}

input.check {
    height: 15px;
    display: inline-block;
    width: auto;
    margin: 0px;
    padding: 0px;
}

label {
    display: inline-block;
    margin: 0px;
}

.cadastro input ::placeholder {
    color: #999;
}

select {
    height: 46px;
    margin-bottom: 15px;
    padding: 0 20px;
    border-radius: 2px;
    color: #777;
    font-size: 20px;
    width: 80%;
    border: 1px solid #ddd;
}

#infos {
    overflow: auto;
}

.cadastro option {
    height: 46px;
    margin-bottom: 15px;
    padding: 0 20px;
    border-radius: 2px;
    font-size: 15px;
    width: 80%;
    border: 1px solid #ddd;
}

.cadastro button {
    color: #fff;
    font-size: 16px;
    background: rgb(15, 32, 108);
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 80%;
}

option {
    height: 100px;
    margin: 0 0;
    padding: 0 0;
    list-style: none;
    border: 1px solid #ccc;
    background-color: white;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}