#main-div {
    overflow: auto;
    height: 55vh;
}

#filtro {
    /*background-image: url('/css/searchicon.png');
    /* Add a search icon to input */
    background-position: 10px 12px;
    /* Position the search icon */
    background-repeat: no-repeat;
    /* Do not repeat the icon image */
    width: 100%;
    /* Full-width */
    font-size: 16px;
    /* Increase font-size */
    padding: 12px 20px 12px 40px;
    /* Add some padding */
    border: 1px solid #ddd;
    /* Add a grey border */
    margin-bottom: 12px;
    /* Add some space below the input */
}

#notas-table {
    border-collapse: collapse;
    /* Collapse borders */
    width: 100%;
    /* Full-width */
    border: 1px solid #F67F3B;
    /* Add a grey border */
    font-size: 16px;
    /* Increase font-size */
}

#notas-table th,
#notas-table td {
    text-align: left;
    /* Left-align text */
    padding: 12px;
    /* Add padding */
}

#notas-table tr {
    /* Add a bottom border to all table rows */
    border-bottom: 1px solid #F67F3B;
}

#notas-table tr:hover {
    /* Add a grey background color to the table header and on hover */
    background-color: #fff1f1;
    cursor: pointer;
}

#notas-table th {
    background-color: #F67F3B;
}

.dataForm {
    height: 75vh;
}

.dataForm input {
    height: 46px;
    font-size: 18px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 5px;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
    width: 600px;
    margin-left: 0px;
    margin-bottom: 50px;
}

#div-buttons {
    display: inline-flex;
}

[type="date"] {
    background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat;
}

[type="date"]::-webkit-inner-spin-button {
    display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}

#div-buttons #voltar-button {
    color: #fff;
    font-size: 16px;
    background: #FF3333;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 20vw;
    margin-left: 0px;
    margin-top: 0;
    align-items: center;
}

#voltar-button:hover {
    background: linear-gradient(135deg, #FF0000 0%, #FF3333 100%);
}

#div-buttons #avanc-button {
    color: #fff;
    font-size: 16px;
    background: #339933;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 20vw;
    margin-left: 25px;
    margin-top: 0;
    align-items: center;
}

#avanc-button:hover {
    background: linear-gradient(135deg, #004400 0%, #33AA33 100%);
}

@media(max-width: 768px) {
    .dataForm textarea {
        height: 8vh;
        margin-bottom: 15px;
        padding: 0 20px;
        border-radius: 2px;
        color: #333;
        display: flex;
        font-size: 15px;
        width: 90%;
        border: 1px solid #999;
    }
    .dataForm input {
        height: 8vh;
        font-size: 18px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        background-color: #fff;
        padding: 3px 5px;
        box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
        width: 250px;
    }
    .dataForm h5 {
        font-size: 16px;
    }
}