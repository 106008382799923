.devForm {
    overflow: auto;
    height: 48vh;
    align-items: flex-start;
}

.devol button {
    color: #fff;
    font-size: 16px;
    background: rgb(15, 32, 108);
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 80%;
}

.devol input {
    height: 46px;
    margin-bottom: 15px;
    padding: 0 20px;
    border-radius: 2px;
    color: #777;
    display: flex;
    font-size: 20px;
    width: 80%;
    border: 1px solid #ddd;
}

.devol p {
    color: #ff3333;
    margin-bottom: 15px;
    padding: 10px;
    width: 100%;
    text-align: center;
}

.devol h6 {
    margin: 15px;
}