#table-div {
    overflow: auto;
    height: 50vh;
}

#itens-drop {
    width: 70%;
}

#final-span {
    margin-left: 15px;
    font-size: 18px;
    color: #FF0000;
    font-style: oblique;
}

#final-butt {
    color: #fff;
    font-size: 16px;
    background: #339933;
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 25%;
    margin-left: 15px;
    margin-top: 0;
    align-items: center;
}

#final-butt:hover {
    background: linear-gradient(135deg, #33AA33 0%, #004400 100%);
}

#add-button {
    color: #fff;
    font-size: 16px;
    background: var(--bg-dark);
    height: 43px;
    border: 0;
    border-radius: 5px;
    width: 25%;
    margin-left: 15px;
    margin-top: 0;
    align-items: center;
}

#add-button:hover {
    background: linear-gradient(135deg, rgb(255, 81, 0) 0%, #FA7F3B 100%);
}

.table-container {
    border-collapse: collapse;
    /* Collapse borders */
    width: 100%;
    /* Full-width */
    border: 1px solid #ddd;
    /* Add a grey border */
    font-size: 16px;
    /* Increase font-size */
}

.table-container input {
    overflow: hidden;
    width: 70px;
}

.table-container tbody {
    overflow: auto;
    height: 40vh;
    width: 100%;
}

.table-container button {
    color: #000;
    font-size: 16px;
    background: var(--bg-dark);
    height: 25px;
    border: 0;
    border-radius: 5px;
    width: auto;
    margin-left: 15px;
    margin-top: 0;
    align-items: center;
}

.table-container th,
.table-container td {
    text-align: left;
    /* Left-align text */
    padding: 12px 8px 12px 8px/* Add padding */
}

.table-container tr {
    /* Add a bottom border to all table rows */
    border-bottom: 1px solid #ddd;
}

.table-container thead {
    background-color: #F67F3B;
}